import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import '../css/Body.css'
import { Link } from 'react-router-dom';

function MyCard(props) {
    return (
        <Card className="shadow" style={{ minWidth: '18rem', maxWidth: '100%', backgroundColor: props.bgColor, color: props.textColor, marginBottom: '30px', padding: '10px' }}>
            <div className="rounded-image">
                <img src={props.imageUrl} style={{ objectFit: 'cover', height: '200px', width: '200px', marginBottom: '20px' }} />
            </div>

            <div>
                <p>
                    <b
                    ><i>{props.title}</i>
                    </b> {props.description}
                    <br></br>
                    {
                        props.more ? <a onClick={() => { props.modal(true) }}>{props.more}</a> : <br></br>

                    }</p>
            </div>
        </Card>
    );
}

export default MyCard;
