import React, { useState, useEffect } from 'react';
import '../css/Body.css';
import '../css/App.css';
import '../css/Services.css';

import { Container, Row, Col } from 'react-bootstrap';

import upload from '../assets/upload-icon.png'
import camera from '../assets/camera.png'
import results from '../assets/results.svg'
import sample_1 from '../assets/sample1.png'

import Card from './card'


const WorksComponent = () => {

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = (data) => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    // <div style={{ marginTop: "30px", paddingTop: "30px", backgroundColor: "#fcf8eb", }} >
    <div style={{backgroundColor: "#fcf8eb", }} >

      <Container>

        {/* <h2>How it works!</h2> */}

        <Row style={{ textAlign: "center"}}>
          <Col>
            <Card
              imageUrl={camera}
              title="Photo:"
              description="Take a picture of your tooth. Let a friend assist if possible. "
              btnType="1"
              // bgColor="#e9b680"
              textColor='#242021'
              modal={openModal}
              more="View sample"
            />
          </Col>

          <Col>
            <Card
              imageUrl={upload}
              title="Upload:"
              description="Upload image by clicking button above."
              buttonText="Get a dental pack"
              // bgColor="#d3cba6"
              textColor='#242021'
            />
          </Col>

          <Col>
            <Card
              imageUrl={results}
              title="Results:"
              description="Wait for a few seconds and get an evaluation."
              buttonText="Get a dental pack"
              // bgColor="#d3cba6"
              textColor='#242021'
            />
          </Col>

        </Row>
      </Container>

      {isModalOpen && (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <div>
              <img src={sample_1} style={{width: "100%"}} />
            </div>

            <br />
            <button className="btnColor" onClick={closeModal}>Close</button>

          </div>
        </div>
      )}

    </div>
  );
}

export default WorksComponent;