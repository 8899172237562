import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/Home';
import Checkup from './pages/Checkup';
import Demo from './pages/Demo';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/" component={HomePage} /> */}
        <Route index element={<HomePage />} />
        <Route path="/checkup" element={<Checkup />} />
        <Route path="/Demo" element={<Demo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
