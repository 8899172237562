import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Container, Row, Col } from 'react-bootstrap';
import Background from '../assets/background.png';
import CheckupComponent from '../components/checkup';


function MantraComp() {

  const styles = {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover', // Optional: Adjust background size
    backgroundPosition: 'center', // Optional: Adjust background position
    width: '100%',
    height: '50vh', // Adjust the height as needed
    // Add any other styles you want
  };
  return (
    // <div style={{ backgroundColor: "#fcf8eb",}}>
    <div style={styles}>
      <Container>
        <Row>
          <Col>
            <div className='App-body-header'>
              <h3 style={{color: '#fff'}}>Fixing Smiles, Booting Confidence</h3>
              <CheckupComponent />

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MantraComp;
