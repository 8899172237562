import '../css/App.css';
import '../css/Demo.css';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Demo = () => {
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isResponseModalOpen, setResponseModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    console.log(1)
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeResponseModal = () => {
    setResponseModalOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === 'myModal') {
      closeModal();
    }
    if (event.target.id === 'responseModal') {
      closeResponseModal();
    }
  };

  return (
    <div style={{ height: "100vh" }} onClick={handleOutsideClick}>
      <iframe
        src={location.state?.website || 'https://example.com'}
        style={{ width: "100%", height: "100%", border: "none" }}
        title="Website"
      ></iframe>

      <div className="floating-button">
        <button id="openModal" className="button" onClick={openModal}>
          Check Your Smile
        </button>
      </div>

      {isModalOpen && (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <br/>
      <i class="firstText">Upload Photo Of Your Tooth</i>
      <br/>
      <input type="file" id="imageInput" accept="image/*" style={{display: "none"}} />
      <button id="uploadButton" class="btn1">Upload</button>

          </div>
        </div>
      )}

      {isResponseModalOpen && (
        <div id="responseModal" className="modal">
          <div className="modal-content">
            <span className="close-response" onClick={closeResponseModal}>&times;</span>
            <p>Response Modal Content</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Demo;
