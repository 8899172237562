import React, { useState, useEffect } from 'react';

import Groq from 'groq-sdk';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import '../css/Body.css';
import '../css/App.css';
import '../css/Services.css';

import { Container, Row, Col } from 'react-bootstrap';

import sample_1 from '../assets/sample1.png'

import Card from './card'


const MoreComponent = () => {

  const [isModalOpen, setModalOpen] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [chatResponse, setChatResponse] = useState("Ask a question\nExample: How do I prevent gum disease?");

  const openModal = (data) => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const chatGroq = async () => {

    const groq = new Groq({
      apiKey: "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", // This is the default and can be omitted
      dangerouslyAllowBrowser: true
    });

    const chatCompletion = await groq.chat.completions.create({
      messages: [{ role: 'user', content: 'Answer the following question only is it is related to dental health and in less than 100 words. Question: ' + chatMessage }],
      model: 'llama3-8b-8192',
    });
    setChatResponse(chatCompletion.choices[0].message.content);
    setChatMessage("");
  }



  return (
    // <div style={{ marginTop: "30px", paddingTop: "30px", backgroundColor: "#fcf8eb", }} >
    <div style={{ backgroundColor: "#fcf8eb", marginTop: 20 }} >

      <Container>

        <textarea
        className='col-text-area'
          value={chatResponse}
          rows="10"
          readOnly
        >
        </textarea>

        <input
          className='col-chat-input'
          type='text'
          value={chatMessage}
          placeholder='Type here'
          onChange={(e) => setChatMessage(e.target.value)}
        />

        <button
          className='btn'
          style={{borderTopRightRadius: 0}}
          onClick={chatGroq}
        >
          <FontAwesomeIcon style={{ fontSize: 30, }} icon={faPaperPlane} />
        </button>

      </Container >

    </div >
  );
}

export default MoreComponent;