import '../css/App.css';
import React from 'react';

// import Navbar from '../components/navbar'
import CheckupComponent from '../components/checkup';

function Checkup() {


  return (
    // <div className='App' style={{backgroundColor: "#fcf8eb", height: "60vh" }}>
    <div className='App' style={{backgroundColor: "#fcf8eb", borderBottom: "1px #000 solid", paddingBottom: 10}}>
        {/* <Navbar /> */}
        <CheckupComponent />
    </div>
  );
}

export default Checkup;

