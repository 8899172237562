import React, { useState } from 'react';
import axios, { Axios } from 'axios';

import '../css/Services.css';


const SubscribeComponent = () => {
  const [send, setSend] = useState("");
  const [isLoading, setIsLoading] = useState("block");
  const [btnColor, setbtnColor] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [isLoaded, setIsLoaded] = useState("");
  const [isLoaded1, setIsLoaded1] = useState(null);
  const [isLoader, setIsLoader] = useState("none");
  const [isModalOpen, setModalOpen] = useState(false);

  const headers = {
    "Accept": "application/json, text/plain, /",
    "Content-Type": "multipart/form-data"
  };
  const api = axios.create({
    baseURL: `https://getasmilefix.com/waitlist.php`,
    headers
  })

  var successStyle = () => {
    setbtnColor("btn-Success")
    setTxtColor("text-Success")
    openModal()

  }

  var errorStyle = () => {
    setbtnColor("btn-Danger")
    setTxtColor("text-Danger")
    openModal()

  }


  var waitList = async () => {

    setIsLoading("none")
    setIsLoader("block")

    let res = await api.post('/', { email: send })

    if (res.data.code == 200) {
      setIsLoading("block")
      setIsLoader("none")
      setIsLoaded("You have successfully subscribe for free quaterly dental check ups")
      setIsLoaded1("You'd be notified when time is due!")
      successStyle()
    }

    else if (res.data.code == 400) {
      setIsLoading("block")
      setIsLoader("none")

      setIsLoaded(res.data.message)
      errorStyle()

    }

    else {
      setIsLoading("block")
      setIsLoader("none")

      setIsLoaded("Sorry, kindly try again")
      errorStyle()

    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  }

  const validate = () => {

    if (validateEmail(send)) {
      waitList()
    } else {
      setIsLoaded("Please enter a valid email")
      errorStyle()
    }
    return false;
  }


  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (

    <div style={{ alignItems: 'center', alignContent: 'center', textAlign: 'center' }} className="col-background" >
      <div className='col-inner'>
        <span className='top-text' style={{ display: isLoading }}>For free dental checkup every 3 months</span>

        <span>
          <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" style={{ height: '100px', display: isLoader }} alt="loading" />
        </span>

        <div style={{ display: isLoading, marginBottom: 20 }}>
          <input
            className='col-form-input'
            type='email'
            placeholder='Your email address'
            onChange={(e) => setSend(e.target.value)} />

          <button
            className='btn'
            onClick={validate}
          >
            Subscribe
          </button>
        </div>
      </div>


      {isModalOpen && (
        <div id="myModal" className="modal">
          <div className="modal-content">
            <br />
            <span className={txtColor}>{isLoaded}</span>
            <span className={txtColor}>{isLoaded1}</span>

            <br />
            <button className={btnColor} onClick={closeModal}>Close</button>

          </div>
        </div>
      )}

    </div>

  );
}

export default SubscribeComponent;