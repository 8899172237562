import React, { useState } from 'react';
import '../css/Body.css';
import '../css/App.css';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import FormData from 'form-data';

// import * as tf from '@tensorflow/tfjs';
// import * as tmImage from '@teachablemachine/image';

// import appstores from '../pngegg.png';


const CheckupComponent = () => {
  const [isLoader, setIsLoader] = useState("none");
  const [isResult, setIsResult] = useState("none");
  const [fileBtn, setFIleBtn] = useState("block");
  const [uploadBtn, setUploadBtn] = useState("none");
  const [selectedFile, setSelectedFile] = useState(null);
  const [result, setResult] = useState(null);
  const [recommendation, setRecommendation] = useState(null);


  const handleUpload = async () => {
    setUploadBtn("none")
    setIsLoader("block")

    if (selectedFile) {
      const formData = new FormData();
      formData.append('fileToUpload', selectedFile);

      try {
        const response = await axios.post('https://getasmilefix.com/master/master.php', formData, {
          // const response = await axios.post('http://localhost/master/master.php', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Handle the response here (e.g., show success message)
        setIsLoader("none")
        setFIleBtn("block");

        if (response.data.code == 200) {
          // window.location.href = "http://localhost/test/2/?results="+btoa(response.data.image);
          window.location.href = "master?results="+btoa(response.data.image);
          setResult(response.data.message);
          setRecommendation(response.data.recommendation);
        } else {
          setResult("Sorry there was an error");
        }
      } catch (error) {
        // Handle errors (e.g., show error message)
        console.error('Error uploading image:', error);
      }

    } else {
      console.log('No file selected');
    }


  }

  // Function to handle file selection
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setFIleBtn("none")
    setUploadBtn("block")

  };

  const startOver = () => {
    setIsResult("none");
    setFIleBtn("block");

  }


  return (
    <div>

      <Container>
        <Row style={{ textAlign: "center", marginTop: 30 }}>

          <Col>
            <div>
              <label
                style={{
                  backgroundColor: "#2e2e2d",
                  borderRadius: 50,
                  color: "#fff",
                  fontSize: 14,
                  padding: 15,
                  marginLeft: 20,
                  marginRight: 20,
                  boxShadow: "3px 3px 5px 0px #9b51e0",
                  display: fileBtn,
                }}

                for="image-selector">
                upload image of your teeth &#8594;
              </label>
              <input
                style={{ opacity: 0 }}
                id="image-selector"
                type="file"
                accept=".jpg, .jpeg, .png" // Specify allowed file types if needed
                onChange={handleFileSelect}
              />

              {/* <div style={{ marginTop: '30px', display: fileBtn }}>
                <h6 className="text-left">**model can only diagnose plaques and decays for now**</h6>
                <h6 className="text-left">*and may produce inconsistent results*</h6>
                <h6 style={{marginTop: '30px'}}>coming soon</h6>
                <img src={appstores} style={{ maxWidth: '100%', height: '100px'}} />
              </div> */}

              <button
                style={{
                  backgroundColor: "#fff",
                  width: '100%',
                  borderRadius: 50,
                  color: "#000",
                  fontSize: 14,
                  padding: 15,
                  display: uploadBtn
                }}

                onClick={handleUpload}>get results &#8594;</button>


              <p className="rotate" alt="loading" style={{ display: isLoader }} onClick={handleUpload}>&#127744;</p>

              <div style={{ display: isResult }}>
                <h4>Summary:</h4>
                <p>{result}</p>
                <p>&nbsp;</p>
                <h4>Recommendation:</h4>
                <p>{recommendation}</p>
                <p>In the meantime, you can support us by subscribing to a dental pack <a href='https://buy.stripe.com/7sI4ja14f7Rf3gkcMN'>here</a></p>

                <button
                  style={{
                    backgroundColor: "#2e2e2d",
                    borderRadius: 50,
                    color: "#fff",
                    fontSize: 14,
                    padding: 15,
                  }}

                  onClick={startOver}>start over &#8635;</button>
              </div>

            </div>

          </Col>

        </Row>
      </Container>

    </div>
  );
}

export default CheckupComponent;